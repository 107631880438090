<template>
	<div v-if="invoice">
		<vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="previewModal"
			:paginate-elements-by-height="1500" pdf-content-width="100%" :manual-pagination="false"
			:html-to-pdf-options="{
				margin: 10,
				image: { type: 'jpeg', quality: 2 },
				enableLinks: true,
				html2canvas: { scale: 1, useCORS: true },
				jsPDF: {
					orientation: 'portrait'
				}
			}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
			<section slot="pdf-content">
				<div class="text-dark" id="ddd">
					<div class="dF aC" style="justify-content: space-between">
						<div>
							<img src="@/assets/logo.png" alt="Bildhive Logo" style="height: 60px" />
							<br />
							<p class="mt-2" style=" width: 250px; font-size: 18px; color: black; ">
								Bildhive Technologies Inc.<br>
								545 N Rivermede Rd, Unit 201<br>
								Concord, ON L4K 4H1
							</p>
						</div>
						<p style="font-size: 32px; font-weight: bold">
							Invoice
						</p>
					</div>

					<div class="dF aC" style="justify-content: space-between">
						<div style="width: 250px">
							<h5>
								<strong> Bill To: </strong>
							</h5>
							<h6>{{ billingName || '' }}<br>{{ billingAddress || '' }}<br>{{ billingPostal || '' }}</h6>
						</div>
						<div class="invoice-details pull-right">
							<a-row :gutter="10" class="aC">
								<a-col :span="10">
									<h6>
										<strong>Project Name:</strong>
									</h6>
								</a-col>
								<a-col :span="14" style="font-size: 16px">
									{{ selectedInvoice?.instance?.name }}
								</a-col>
							</a-row>
							<a-divider style="background: black; margin: 2px 0px"></a-divider>
							<a-row :gutter="10" class="aC">
								<a-col :span="10">
									<h6>
										<strong>Project ID:</strong>
									</h6>
								</a-col>
								<a-col :span="14" style="font-size: 16px">
									{{ selectedInvoice?.instance?.readableId || '' }}
								</a-col>
							</a-row>
							<a-divider style="background: black; margin: 2px 0px"></a-divider>
							<a-row :gutter="10" class="aC">
								<a-col :span="10">
									<h6>
										<strong>Invoice Number:</strong>
									</h6>
								</a-col>
								<a-col :span="14" style="font-size: 16px">
									{{ selectedInvoice?.invoiceNumber }}
								</a-col>
							</a-row>

							<a-divider style="background: black; margin: 2px 0px"></a-divider>

							<a-row :gutter="10" class="aC">
								<a-col :span="10">
									<h6><strong>Invoice Date:</strong></h6>
								</a-col>
								<a-col :span="14" style="font-size: 16px">
									{{ selectedInvoice && (selectedInvoice.fromDate ? selectedInvoice.fromDate :
										getDate(selectedInvoice.createdAt)) }}
								</a-col>
							</a-row>

							<a-divider style="background: black; margin: 2px 0px"></a-divider>

							<a-row :gutter="10" class="aC">
								<a-col :span="10">
									<h6><strong>Customer ID:</strong></h6>
								</a-col>
								<a-col :span="14" style="font-size: 16px">
									{{ selectedInvoice?.userId }}
								</a-col>
							</a-row>
						</div>
					</div>

					<a-divider class="mt-3" style="background: black; margin: 10px 0px"></a-divider>

					<div style="font-size: 16px" v-if="selectedInvoice?.paymentMode !== 'offline'">
						<div>
							<strong>Payment Method </strong>
						</div>

						<div>
							Credit Card
							<strong>
								{{
									paymentMethodCard
								}}
							</strong>
						</div>
					</div>

					<div class="mt-2" v-if="selectedInvoice">
						<a-row :gutter="10" class="aC" style="font-size: 16px; font-weight: bold">
							<a-col :span="4"> Product Code</a-col>
							<a-col :span="6"> Item Description</a-col>
							<a-col :span="3"> From</a-col>
							<a-col :span="3"> To</a-col>
							<a-col :span="2"> Qty</a-col>
							<a-col :span="3"> Unit Price</a-col>
							<a-col :span="3"> Total</a-col>
						</a-row>
						<a-divider style="background: black; margin: 10px 0px"></a-divider>
						<a-row :gutter="10" class="aC" style="font-size: 14px"
							v-if="(selectedInvoice?.details?.package) || (selectedInvoice?.details?.usageDetails)">
							<a-col :span="4">
								{{ selectedInvoice.details?.package?.itemCode ? selectedInvoice.details.package.itemCode
									: (selectedInvoice.details?.usageDetails?.itemCode || '') }}</a-col>
							<a-col :span="6" class="text-uppercase">
								{{ selectedInvoice.details?.package?.name ? selectedInvoice.details.package.name +
									'Project' : (selectedInvoice.details?.usageDetails?.name || '') }}
							</a-col>
							<a-col :span="3">
								{{ selectedInvoice && (selectedInvoice.fromDate ? selectedInvoice.fromDate :
									getDate(selectedInvoice.createdAt)) }}
							</a-col>
							<a-col :span="3">
								{{ selectedInvoice && (selectedInvoice.toDate ? selectedInvoice.toDate :
									!selectedInvoice.validUntil ? "N/A" : getDate(+selectedInvoice.validUntil)) }}</a-col>
							<a-col :span="2"> 1</a-col>
							<a-col :span="3">
								<template v-if="selectedInvoice.details?.package">
									${{ selectedInvoice.details.package.price?.toLocaleString("en-US", {
										useGrouping: false,
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}) || (0).toFixed(2) }}
								</template>
								<template v-else-if="selectedInvoice.details?.usageDetails">
									${{ selectedInvoice.details.usageDetails.price?.toLocaleString("en-US", {
										useGrouping: false,
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}) || (0).toFixed(2) }}
								</template>
								<template v-else>
									${{ (0).toFixed(2) }}
								</template>
							</a-col>
							<a-col :span="3">
								<template v-if="selectedInvoice.details?.package">
									${{ selectedInvoice.details.package.price?.toLocaleString("en-US", {
										useGrouping: false,
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}) || (0).toFixed(2) }}
								</template>
								<template v-else-if="selectedInvoice.details?.usageDetails">
									${{ selectedInvoice.details.usageDetails.price?.toLocaleString("en-US", {
										useGrouping: false,
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}) || (0).toFixed(2) }}
								</template>
								<template v-else>
									${{ (0).toFixed(2) }}
								</template>
							</a-col>
						</a-row>
						<div v-for="(additional, index) in extraPackages" :key="index" class="py-2">
							<a-row :gutter="10" class="aC" style="font-size: 14px">
								<a-col :span="4">
									{{ additional.itemCode || '' }}</a-col>
								<a-col :span="6" class="text-uppercase">
									{{ additional.name }}
								</a-col>
								<a-col :span="3">
									{{ selectedInvoice.fromDate ? selectedInvoice.fromDate :
										getDate(selectedInvoice.createdAt) }}
								</a-col>
								<a-col :span="3">
									{{ selectedInvoice.toDate ? selectedInvoice.toDate : !selectedInvoice.validUntil ?
										"N/A" :
										getDate(+selectedInvoice.validUntil) }}</a-col>
								<a-col :span="2"> {{ additional.quantity }}</a-col>
								<a-col :span="3">
									<span v-if="additional.quantity && additional.price">
										<span v-if="(additional.price / additional.quantity) >= 0.1">
											${{ (additional.price / additional.quantity).toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2) }}
										</span>
										<span v-else>
											N/A
										</span>
									</span>
									<span v-else>
										${{ additional.price?.toLocaleString("en-US", {
											useGrouping: false,
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										}) || (0).toFixed(2) }}
									</span>
								</a-col>
								<a-col :span="3">
									${{ additional.price?.toLocaleString("en-US", {
										useGrouping: false,
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}) || (0).toFixed(2) }}</a-col>
							</a-row>
						</div>
					</div>
					<a-divider class="mt-3" style="background: black; margin: 10px 0px"></a-divider>

					<div v-if="selectedInvoice" class="dF aC mt-5"
						style="justify-content: space-between; font-size: 14px;">
						<div style="width: 250px">
							<div style="border: 2px solid; padding: 10px">
								<h6>
									<strong>How to contact us: </strong>
								</h6>
								<div class="mt-3">
									For any billing inquiries please email us at: billing@bildhive.com, or call our toll
									free number 1.877.977.4483 (HIVE).
								</div>
							</div>
							<div class="mt-3 px-3 py-2" style="border: 2px solid">
								Payments made payable to:<br><strong>Bildhive Technologies Inc.</strong><br><br>
								Bildhive GST ID:<br>798826608RT0001
							</div>
						</div>
						<div class="price-details pull-right ml-3 mr-2">
							<a-row :gutter="10" class="aC">
								<a-col :span="12">
									<h6>Subtotal</h6>
								</a-col>
								<a-col :span="12" style="text-align: right">
									<h6>${{ selectedInvoice?.subTotal?.toLocaleString("en-US", {
										useGrouping: false,
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}) || (0).toFixed(2) }}</h6>
								</a-col>
							</a-row>

							<a-row :gutter="10" class="aC" v-if="checkAmount(selectedInvoice.details, 'deductions')">
								<a-col :span="18">
									<h6>Total Deductions <span v-if="selectedInvoice?.details?.deductions?.length">({{
										selectedInvoice.details.deductions.map(d => d.name).join(", ") }})</span>
									</h6>
								</a-col>
								<a-col :span="6" style="text-align: right">
									<h6 style="color: red">
										-${{
											selectedInvoice?.details?.deductions?.reduce((acc, deduction) => {
												return acc + deduction.price;
											}, 0)
												.toLocaleString("en-US", {
													useGrouping: false,
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												}) || (0).toFixed(2)
										}}
									</h6>
								</a-col>
							</a-row>

							<a-row :gutter="10" class="aC" v-if="checkAmount(selectedInvoice.details, 'discounts')">
								<a-col :span="18">
									<h6>Total Discount <span
											v-if="selectedInvoice?.details?.appliedDiscounts?.length">({{
												selectedInvoice.details.appliedDiscounts.map(d => d.name).join(", ")
											}})</span>
									</h6>
								</a-col>
								<a-col :span="6" style="text-align: right">
									<h6 style="color: red">
										-${{ selectedInvoice?.details?.appliedDiscounts?.reduce((acc, discount) => {
											return acc + discount.price;
										}, 0)
											.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2)
										}}
									</h6>
								</a-col>
							</a-row>

							<a-row :gutter="10" class="aC">
								<a-col :span="18">
									<h6>Total Tax <span v-if="selectedInvoice?.details?.taxes?.length">({{
										selectedInvoice.details.taxes.map(t => t.name).join(", ") }})</span></h6>
								</a-col>
								<a-col :span="6" style="text-align: right">
									<h6>${{ selectedInvoice.details?.taxes?.reduce(
										(acc, tax) => {
											return acc + tax.price;
										},
										0
									).toFixed(2) || (0).toFixed(2)
										}}</h6>
								</a-col>
							</a-row>

							<a-row :gutter="10" class="aC">
								<a-col :span="18">
									<h6><strong>Total</strong></h6>
								</a-col>
								<a-col :span="6" style="text-align: right">
									<h6>
										<strong>${{
											selectedInvoice?.total?.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2)
										}}</strong>
									</h6>
								</a-col>
							</a-row>

							<a-row :gutter="10" class="aC">
								<a-col :span="18">
									<h6>Currency</h6>
								</a-col>
								<a-col :span="6" style="text-align: right">
									<h6>{{ selectedInvoice?.currency || 'USD' }}</h6>
								</a-col>
							</a-row>

							<a-divider></a-divider>

							<a-row :gutter="10" class="aC text-sm">
								<div v-if="selectedInvoice.instance_feature_log?.description" class="mb-3">
                                    <h6><strong>Invoice Description</strong></h6>
    								<h6>{{ selectedInvoice.instance_feature_log.description }}</h6>
                                </div>
                                <div v-if="selectedInvoice.promoCode" class="mb-3">
                                    <h6><strong>Promo Code Description</strong></h6>
    								<h6>{{ selectedInvoice.promoCode }}: {{ getPromoCodeDescription(selectedInvoice.promoCode) }}</h6>
                                </div>
							</a-row>
						</div>
					</div>

					<div v-if="selectedInvoice?.payments?.length">
						<a-divider class="mt-5" style="background: black; margin: 10px 0px"></a-divider>
						<div style="font-size: 16px">
							<div class="text-center">
								<strong>Invoice Offline Payments</strong>
							</div>
						</div>
						<a-divider class="mt-3" style="background: black; margin: 10px 0px"></a-divider>

						<div class="mt-2">
							<a-row :gutter="10" class="aC" style="font-size: 16px; font-weight: bold">
								<a-col :span="4"> Amount </a-col>
								<a-col :span="6"> Payment Date </a-col>
								<a-col :span="6"> Payment Mode </a-col>
								<a-col :span="8"> Note </a-col>
							</a-row>
							<a-divider style="background: black; margin: 10px 0px"></a-divider>
							<div class="my-2">
								<a-row v-for="(payment, index) in selectedInvoice.payments" :key="index" :gutter="10"
									class="aC py-1" style="font-size: 14px">
									<a-col :span="4">
										${{ payment?.amount?.toLocaleString("en-US", {
											useGrouping: false,
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										}) || 0 }} ({{ selectedInvoice.currency || "USD" }}) </a-col>
									<a-col :span="6">
										{{ getDateTime(payment.paymentDate) }}
									</a-col>
									<a-col :span="6">
										{{ payment.paymentMode === 'check' ? 'Check' : payment.paymentMode ===
											'bank_transfer' ? 'Bank Transfer' : 'Cash' }}
									</a-col>
									<a-col :span="8">{{ payment.note }}</a-col>
								</a-row>
							</div>
						</div>
						<a-divider class="mt-3" style="background: black; margin: 10px 0px"></a-divider>
					</div>


					<div v-if="selectedInvoice?.refunds?.length">
						<a-divider class="mt-5" style="background: black; margin: 10px 0px"></a-divider>
						<div style="font-size: 16px">
							<div class="text-center">
								<strong>Invoice Refunds</strong>
							</div>
						</div>
						<a-divider class="mt-3" style="background: black; margin: 10px 0px"></a-divider>

						<div class="mt-2">
							<a-row :gutter="10" class="aC" style="font-size: 16px; font-weight: bold">
								<a-col :span="4"> Amount </a-col>
								<a-col :span="6"> Refund Date </a-col>
								<a-col :span="8"> Reason </a-col>
							</a-row>
							<a-divider style="background: black; margin: 10px 0px"></a-divider>
							<div class="my-2">
								<a-row v-for="(refund, index) in selectedInvoice.refunds" :key="index" :gutter="10"
									class="aC py-1" style="font-size: 14px">
									<a-col :span="4">
										${{ refund?.amount?.toLocaleString("en-US", {
											useGrouping: false,
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										}) || 0 }} ({{ selectedInvoice.currency || "USD" }}) </a-col>
									<a-col :span="6">
										{{ getDateTime(refund.createdAt) }}
									</a-col>
									<a-col :span="8">{{ refund.reason }}</a-col>
								</a-row>
							</div>
						</div>
						<a-divider class="mt-3" style="background: black; margin: 10px 0px"></a-divider>
					</div>
				</div>
			</section>
		</vue-html2pdf>
	</div>
</template>

<script>
	import VueHtml2pdf from "vue-html2pdf";
	import moment from "moment"
	import { mapState } from "vuex";

	export default {
		components: {
			VueHtml2pdf
		},
		props: {
			invoice: {
				type: Object,
				default: () => null
			},
			userPaymentMethods: {
				type: Array,
				default: () => [],
			},
            previewModal: {
                type: Boolean,
                default: false
            }
		},

		data() {
			return {
				selectedInvoice: null,
				countryList: {
					CA: "Canada",
					US: "United States",
				}
			}
		},

		computed: {
			...mapState(["promoCodes"]),

			billingName() {
				if (this.selectedInvoice?.billTo) {
					return `${this.selectedInvoice.billTo.billingName}`;
				} else if (this.invoiceOwner) {
					return `${this.invoiceOwner.firstName || ''} ${this.invoiceOwner.lastName || ''}`;
				}
				return "";
			},
			billingAddress() {
				if (this.selectedInvoice?.billTo) {
					return `${this.selectedInvoice.billTo.address || ''}`;
				} else if (this.selectedInvoice?.instance) {
					return `${this.selectedInvoice.instance.address || ''}`;
				}
				return "";
			},
			billingPostal() {
				if (this.selectedInvoice?.billTo) {
					let billTo = this.selectedInvoice.billTo
					return `${billTo.city || ''}, ${billTo.region || ''} ${billTo.postalCode || ''}, ${this.countryList[billTo.country] || billTo.country || ''}`;
				} else if (this.selectedInvoice?.instance && this.invoiceOwner) {
					let instance = this.selectedInvoice.instance
					return `${instance.city || ''}, ${instance.region || ''} ${instance.postalCode || ''}, ${this.countryList[instance.country] || instance.country || ''}`;
				}
				return "";
			},
            invoiceOwner() {
                const { billingOwner, owner } = this.selectedInvoice?.instance || {};
                if (billingOwner === this.user.id || owner === this.user.id) {
                    return this.user;
                }
                return billingOwner || owner || {};
            },

			user() {
				return this.$store.state.user.user;
			},

			paymentMethodCard() {
				if (this.selectedInvoice) {
					if (this.selectedInvoice.instance?.paymentMethod?.details) {
						return this.selectedInvoice.instance.paymentMethod.details.cardNo.slice(-4)
					}
					const found = this.userPaymentMethods.find(
						(x) => x.id == this.selectedInvoice.instance.paymentMethod
					);
					if (found?.details) {
						return found.details.cardNo.slice(-4)
					}
				}
				return "";
			},

			extraPackages() {
				if (this.selectedInvoice?.details) {
					const packages = JSON.parse(JSON.stringify(this.selectedInvoice.details.selectedPackageExtras || []));

					return packages;
				}
				return []
			}
		},

		watch: {
			invoice(newValue) {
				if (newValue && Object.keys(newValue).length) {
					this.selectedInvoice = newValue;
					this.downloadPDF();
				} else {
					this.selectedInvoice = null
				}
			},
		},


		methods: {
			checkAmount(obj, type) {
				if (type == 'deductions') {
					let amount = obj?.deductions?.reduce((acc, discount) => {
						return acc + discount.price;
					}, 0) || 0;
					return amount > 0
				} else if (type == 'discounts') {
					let amount = obj?.appliedDiscounts?.reduce((acc, discount) => {
						return (
							acc + discount.price
						);
					}, 0) || 0;
					return amount > 0
				} else {
					return true
				}
			},
			async downloadPDF() {
				this.$nprogress.start();
				// Put it in a setTimeout to make sure the PDF is rendered
				this.$nextTick(() => {
					this.$refs.html2Pdf.generatePdf();
				});
			},

			async beforeDownload({ html2pdf, options, pdfContent }) {
                if (this.previewModal) {
                    this.$nprogress.done();

                    // Check if the print window is opened or not in the interval with max check count
                    let maxCheckCount = 5;
                    const checkPrintWindow = setInterval(() => {
                        // From all the current window get the window which has location.href is starting with `blob:`
                        // This is the case when user is trying to preview print pdf invoice
                        const printWindow = Array.from(window).find(w => w.location.href.startsWith('blob:'));
                        // If print window is found then attach event listener to detect print dialog close
                        // As we can't detect print dialog close event directly we are checking if the print window is closed or no in the interval
                        if (printWindow) {
                            clearInterval(checkPrintWindow);
                            console.log("Print window opened");
                            const checkClose = setInterval(() => {
                                if (printWindow.closed) {
                                    clearInterval(checkClose);
                                    this.$emit('close')
                                    console.log("Print window closed");
                                }
                            }, 500);
                        } else {
                            maxCheckCount--;
                            if (maxCheckCount <= 0) {
                                clearInterval(checkPrintWindow);
                                console.log("Print window not opened");
                                this.$emit('close')
                            }
                        }
                    }, 1000)

                    return
                }
				options.filename = (this.selectedInvoice?.instance && `${this.selectedInvoice.instance.name}-${this.selectedInvoice.invoiceNumber}`) || 'Invoice';
				await html2pdf()
					.set(options)
					.from(pdfContent)
					.toPdf()
					.get("pdf")
					.then((pdf) => {
						const totalPages = pdf.internal.getNumberOfPages();
						for (let i = 1; i <= totalPages; i++) {
							pdf.setPage(i);
							pdf.setFontSize(11);
							pdf.text(
								"Page " + i,
								pdf.internal.pageSize.getWidth() * 0.88,
								12
							);
						}
					})
					.save()
					.then(() => {
						this.$nprogress.done();
						this.$emit('close')
					}).catch(() => {
						this.$nprogress.done();
					});
			},
			getDate(date) {
				return moment(date).format("DD/M/YYYY");
			},
			getDateStart(date) {
				return moment(date).startOf('month').format("DD/M/YYYY");
			},
			getDateTime(date) {
				return moment(date).format("DD/M/YYYY hh:mm A");
			},

			getPromoCodeDescription(code) {
				if (code) {
					const foundPC = this.promoCodes.find(p => p.code == code)
					if (foundPC) return foundPC.description || ''
				}
				return ''
			}
		},
	}
</script>


<style lang="scss" scoped>
	.invoice-details {
		width: 380px;
		background-color: rgb(247, 245, 249);
		font-size: 18px;
		padding: 10px;
	}

	.price-details {
		width: 425px;
		font-size: 18px;
		padding: 10px;
	}

	.ant-divider-horizontal {
		height: 1.5px;
	}
</style>
